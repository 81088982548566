<template>
  <v-app>
    <Header></Header>
    <div class="auth-wrapper d-flex justify-content-center align-items-center">
      <router-view/>
    </div>
  </v-app>
</template>

<script>
import Header from "@/components/header/Header.vue";

export default {
  name: 'Auth',
  components: {Header}
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #093545;
  background-image: url("../assets/img/auth/footer.svg") ;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
</style>
